<template>
	<div>
		<el-form ref="queryForm" :inline="true" :model="queryForm" size="small">
			<el-form-item label="告警类型" prop="type">
				<el-select v-model="queryForm.type" placeholder="请选择" clearable>
					<el-option label="SOS" :value="1"></el-option>
					<el-option label="燃气警报" :value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="处理状态" prop="status">
				<el-select v-model="queryForm.status" placeholder="请选择" clearable>
					<el-option label="未处理" :value="0"></el-option>
					<el-option label="已响应" :value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button @click="handleSearch" type="primary">查询</el-button>
				<el-button @click="handleReset">重置</el-button>
			</el-form-item>
		</el-form>

		<el-table :data="tableData" v-loading="tableLoading" border stripe
			:header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px"
			style="width: 100%;">
			<el-table-column label="#" type="index" width="60"></el-table-column>
			<el-table-column label="姓名" prop="member.realname"></el-table-column>
			<el-table-column label="事件时间" prop="created_at"></el-table-column>
			<el-table-column label="房间号">
				<template v-slot="{ row }">
					<span v-if="row.member">
						{{ `${row.member.room.floor.building.area.name}${row.member.room.floor.building.name}${row.member.room.floor.name}${row.member.room.name}` }}
					</span>
					<span v-else>{{ row.device.location }}</span>
				</template>
			</el-table-column>
			<el-table-column label="告警类型" v-slot="{ row }">
				<el-tag :type="row.type ? alarmDict[row.type].type : 'warn'" size="small">
					{{ row.type ? alarmDict[row.type].label : ''}}
				</el-tag>
			</el-table-column>
			<el-table-column label="过往危急病症">
				<template v-slot="{ row }">
					<span>{{ row.member ?  row.member.medical_history : ''}}</span>
				</template>
			</el-table-column>
			<el-table-column label="抢救预案">
				<template v-slot="{ row }">
					<span>{{ row.member ?  row.member.rescue_plan : ''}}</span>
				</template>
			</el-table-column>
			<el-table-column label="处理状态">
				<template v-slot="{ row }">
					<el-tag v-if="row.status === 0" type="warning" size="small">未处理</el-tag>
					<el-tag v-else type="success" size="small">已响应</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="处理人" prop="user.name"></el-table-column>
			<el-table-column label="操作">
				<template v-slot="{ row }">
					<el-button v-if="row.status < 2" type="primary" size="mini" @click="handle(row)"
						class="margin-r-10">处理</el-button>
					<el-button v-if="row.member" type="primary" size="mini" @click="vallVideo(row.member_id)"
						class="margin-r-10">呼叫</el-button>
				</template>
			</el-table-column>
		</el-table>

		<div class="margin-t-10 flex">
			<el-pagination background layout="prev, pager, next, total, jumper" :total="total"
				:current-page.sync="queryForm.page" :page-size="queryForm.page_size" @current-change="getTableData">
			</el-pagination>
			<el-button size="small">确定</el-button>
		</div>

		<el-dialog title="报警处理" :visible.sync="showDialog" :before-close="closeDialog" :close-on-click-modal="false"
			width="550px">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px">
				<el-form-item label="姓名" prop="member_id">
					<el-select v-model="form.member_id" clearable remote placeholder="请选择" class="el-input_inner--rewrite">
						<el-option v-for="item in recordData" :key="item.id" :label="item.realname" :value="item.id">
							<div class="flex justify-content-s-b">
								<span>{{ item.realname }}</span><span>{{ item.birthday }}</span>
							</div>
						</el-option>
						<el-pagination background layout="prev, pager, next, total" :total="recordTotal"
							:current-page.sync="recordForm.page" :page-size="recordForm.page_size" @current-change="getRecordData">
						</el-pagination>
					</el-select>
				</el-form-item>
				<el-form-item label="事件时间" prop="record_time">
					<el-date-picker v-model="form.record_time" type="datetime" placeholder="选择日期时间"
						value-format="yyyy-MM-dd HH:mm:ss" class="el-input_inner--rewrite">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="风险等级" prop="label">
					<el-select v-model="form.label" placeholder="请选择">
						<el-option label="低风险" :value="1"></el-option>
						<el-option label="中风险" :value="2"></el-option>
						<el-option label="高风险" :value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="记录内容" prop="record_content">
					<el-input v-model="form.record_content" placeholder="请输入" type="textarea"></el-input>
				</el-form-item>
				<el-form-item label="处理状态" prop="state">
					<el-radio-group v-model="form.state">
						<el-radio :label="0">未处理</el-radio>
						<el-radio :label="1">已处理</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="处理意见" prop="remark">
					<el-input v-model="form.remark" placeholder="请输入" type="textarea"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer">
				<el-button @click="closeDialog">取消</el-button>
				<el-button @click="handleSubmit" type="primary">提交</el-button>
			</span>
		</el-dialog>

		<VideoCallDialog ref="videoCall"></VideoCallDialog>

	</div>
</template>


<script>
	import {
		getAlarmListAPI,
		// alarmResponseAPI,
		safetyDisposalAPI,
	} from '@/api/safety.js'
	import VideoCallDialog from '@/components/VideoCallDialog.vue'
	import {
		getUserRecordDataAPI
	} from '@/api/user-record/record.js'

	export default {
		name: 'alarm',
		components: {
			VideoCallDialog,
		},
		data() {
			return {
				// 查询相关参数
				queryForm: {
					page: 1,
					page_size: 10,
					status: '',
					type: '',
				},
				// 表格数据 总长度
				total: 1,
				// 表格数据
				tableData: [],
				// 表格加载状态
				tableLoading: false,

				// 告警类型字典
				alarmDict: {
					1: {
						label: 'SOS',
						type: 'danger'
					},
					2: {
						label: '燃气报警',
						type: 'warning'
					},
					3: {
						label: '摔倒报警',
						type: 'warning'
					},
					4: {
						label: '离床报警',
						type: 'warning'
					},
					5: {
						label: '派车提示',
						type: ''
					},
					6: {
						label: '点餐提示',
						type: ''
					},
					7: {
						label: '心率异常',
						type: 'danger'
					},
					8: {
						label: '呼吸异常',
						type: 'danger'
					}
				},

				// 弹窗显示状态
				showDialog: false,
				// 弹窗内容
				form: {
					member_id: '',
					record_time: '',
					record_content: '',
					label: '',
					state: 0,
					remark: '',
					user_id: '',
					alarm_id: '',
				},
				rules: {
					member_id: [{
						required: true,
						message: '姓名不能为空',
						trigger: 'change'
					}],
					record_time: [{
						required: true,
						message: '记录时间不能为空',
						trigger: 'change'
					}],
					record_content: [{
						required: true,
						message: '记录内容不能为空',
						trigger: 'change'
					}],
					label: [{
						required: true,
						message: '风险等级不能为空',
						trigger: 'blur'
					}],
					state: [{
						required: true,
						message: '处理状态不能为空',
						trigger: 'change'
					}],
					remark: [{
						required: true,
						message: '处理意见不能为空',
						trigger: 'change'
					}],
				},
				// 报警处理老人列表信息
				recordData: [],
				// 请求老人列表参数
				recordForm: {
					page: 1,
					page_size: 10,
				},
				// 老人列表总数
				recordTotal: 1,
			}
		},
		created() {
			this.getTableData();
		},
		methods: {
			// 获取表格数据
			getTableData() {
				this.tableLoading = true;
				getAlarmListAPI(this.queryForm).then(res => {
					this.tableLoading = false;
					this.tableData = res.data;
					this.total = res.total;
				})
			},
			// 查询
			handleSearch() {
				this.queryForm.page = 1;
				this.getTableData();
			},
			// 重置搜索条件
			handleReset() {
				this.$refs.queryForm.resetFields()
			},
			// 处理告警
			handle(row) {
				this.showDialog = true;
				this.form.member_id = row.member_id ? row.member_id : '';
				this.form.alarm_id = row.id;
				this.form.state = 1;
				this.getRecordData();
			},
			// 获取档案数据
			getRecordData() {
				getUserRecordDataAPI(this.recordForm).then(res => {
					this.recordData = res.data
					this.recordTotal = res.total
				})
			},
			// 发起时视频通话
			vallVideo(id) {
				this.$refs.videoCall.open(id)
			},
			// 关闭弹窗
			closeDialog() {
				this.$refs.form.resetFields()
				this.showDialog = false
			},
			// 弹窗提交
			handleSubmit() {
				this.$refs.form.validate(isValid => {
					if (!isValid) return
					safetyDisposalAPI(this.form).then(() => {
						this.$message.success('处理成功');
						this.getTableData();
						this.closeDialog();
					})
				})
			},
		}
	}
</script>


<style scoped>

</style>