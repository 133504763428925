// 安全处理
import request from '@/utils/util.js'

// 新增
export function addSafetyDataAPI(data) {
	return request.post('/safety_disposal', data)
}

// 修改
export function updateSafetyDataAPI(id, data) {
	return request.put(`/safety_disposal/${id}`, data)
}

// 列表
export function getSafetyDataAPI(data) {
	return request.get('/safety_disposal', data)
}

// 删除
export function delSafetyDataAPI(id) {
	return request.del(`/safety_disposal/${id}`)
}



// 获取一键告警 列表
export function getAlarmListAPI(data) {
	return request.get('/alarm/list', data)
}

// 处理告警
export function alarmResponseAPI(data) {
	return request.post('/alarm/response', data)
}

// 一键告警 处理 信息落到 安全处理
export function safetyDisposalAPI(data) {
	return request.post('/safety_disposal', data)
}